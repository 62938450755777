import { MDBCard, MDBCardBody, MDBRow,MDBCardVideo,MDBContainer} from "mdbreact";
import "./FieldCompPage.css";
import { useTranslation } from "react-i18next";

const FieldCompPage = (uuid) => {
  const { t } = useTranslation(["field"]);
  return (
    <section id="kstarCard">
      <MDBContainer>
         <MDBRow>
              <video muted loop autoPlay controls style={{ width: "100%" }}>
                <source
                  src="https://tritech.s3.ap-northeast-2.amazonaws.com/videos/tritech-concept-v2.mp4"
                  type="video/mp4"
                />
              </video>
          </MDBRow>
      </MDBContainer>
      <MDBContainer>
          <MDBRow>
          <img
                        src={t("I01")}
                        alt="Our main strength is having a lot of experience converging those technologies  
                        M&S
                        (Modeling &
                        Simulation) AI DigitalTwin Metaverse
                        (VR, AR, XR)
                        ICT
                        Cloud 
                        Platform                                                "
                        className="img-fluid"
                        style={{ width: "100%" }}
                      />
          </MDBRow>
      </MDBContainer>
      <MDBContainer>
          <MDBRow>
          <img
                        src={t("I02")}
                        alt="Advancing Digital Twin Platform Technology 
                        and Expanding by Industry 
                        B2B Application
                        Advancement of technology
                        B2C commercialization"
                        className="img-fluid"
                        style={{ width: "100%" }}
                      />
          </MDBRow>
       </MDBContainer>
       <MDBContainer>
          <MDBRow>
          <img
                        src={t("I03")}
                        alt="Ship/Plant
                        Dismantle
                        Semiconductor
                        secondary 
                        battery
                        National defense
                        Disaster
                        VR
                        Development
                        Metaverse
                        Platform
                        AR
                        Applied 
                        technology
                        Metaverse
                        Shopping
                        "
                        className="img-fluid"
                        style={{ width: "100%" }}
                      />
          </MDBRow>
        </MDBContainer>
    </section>
  );
};

export default FieldCompPage;

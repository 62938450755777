import Copyrights from "../../../Footer";
import TopImgPageFieldPage from "../../TopImgFd/TopImgPageFieldPage";
import TopImgPageFieldPageMobile from "../../TopImgFd/TopImgPageFieldPageMobile";
import "./subPageFd.css";
import FireInner from "./FireInner";
import TopImgContactedMobile from "../../TopImgFd/TopImgContactedMobile";
import TopImgContactedWeb from "../../TopImgFd/TopImgContactedWeb";

const Fire = () => {
  return (
    <div id="subPageFd">
      <div className="desktop">
        <TopImgContactedWeb />
        <FireInner />
      </div>

      <div className="mobile">
        <TopImgContactedMobile />
        <FireInner />
      </div>
  <Copyrights />
  </div>
  );
};
export default Fire;

const _cardIct = [
    {
      uuid: "1",
      eng: "E-mail",
      password: "사원번호"

    },
 



  ]

  export default _cardIct;

import Copyrights from "../../../Footer";
//import TopImgPageFieldPage from "../../TopImgFd/TopImgPageFieldPage";
import "./subPageFd.css";
// import TopImgPageProjects from "../../FieldPageFd/component/TopImgPageProjects";
//import TopImgPageFieldPageMobile from "../../TopImgFd/TopImgPageFieldPageMobile";
import IctProjectsPage from "../component/IctProjectsPage";
import IctProjectsPageMobile from "../component/IctProjectsPageMobile";
import TopImgContactedMobile from "../../TopImgFd/TopImgContactedMobile";
import TopImgContactedWeb from "../../TopImgFd/TopImgContactedWeb";

const Dipp = () => {
  return (
    <div id="subPageFd">
      <div className="desktop">
        <TopImgContactedWeb />
        <IctProjectsPage />
      </div>

      <div className="mobile">
        <TopImgContactedMobile />
        <IctProjectsPageMobile />
      </div>

      <Copyrights />
    </div>
  );
};

export default Dipp;

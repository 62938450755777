import { MDBCard, MDBCardBody, MDBRow, MDBCol,MDBView ,MDBMask  } from "mdbreact";
import { Link } from 'react-router-dom';
import "./KstarCompPage.css";
import { useTranslation } from "react-i18next";
const KstarCompPage = (uuid) => {
  const { t } = useTranslation(["kstar"]);
  return (
    <section id="kstarCard">
      <div id ="card">
      <div id ="row">
          <Link to="/pages/AboutIndexFd/subPageFd/kstar">
          <img className="img-fluid" src={t("I01")} alt="Virtual KSTAR 
(Digital Twin for fusion)
Monitoring Virtual KSTAR 
experiments in real time and Visualization of implementing 
simulation results
" />
          </Link>
          <Link to="/pages/AboutIndexFd/subPageFd/dlpp">
          <img className="img-fluid" src={t("I02")} alt="RVI Decommissioning
Platform
RVI dismantling scenario verification 
system based on simulation of 
automatic dismantling of optimal 
dismantling path in internal space 
of nuclear power plant
" />
          </Link>
          </div>
          <div id ="row">
          <Link to="/pages/AboutIndexFd/subPageFd/Fire">
              <img className="img-fluid" src={t("I03")}  alt="K-Virtual Ladder Truck Simulator
(K-VLTS)
Development of the fire 
ladder truck training simulator suitable 
for fire fighting of high rise building 
through the virtual fire environment 
" />
          </Link>
          <Link to="/pages/AboutIndexFd/subPageFd/Voucher">
              <img className="img-fluid" src={t("I04")} alt="AR Service APP
Interior Design and Ordering System
Development of a platform that 
enables design selection and ordering 
For interior construction based on 
augmented reality
" />
          </Link>
          </div>
          <div id ="row">
          <Link to="#!">
              <img className="img-fluid" src={t("I05")} alt="For 3D animaion production
3D Studio platform
Integrated 3D animation service platform 
supporting co-producing based on 
cloud computing.
Including AI lighting, AI rendering 
production and online 
recruitment module.
" />
          </Link>
          <img></img>
          </div>
      </div> 
    </section>
  );
};

export default KstarCompPage;

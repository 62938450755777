import { Component, Fragment } from "react";

import { MDBBtn, MDBCardBody, MDBContainer, MDBRow } from "mdbreact";

import Copyrights from "../../Footer";
import TopImgCompanyMobile from "../TopImgFd/TopImgContactedMobile";
import TopImgCompanyWeb from "../TopImgFd/TopImgContactedWeb";
import "./Ceo.css";
import { useTranslation } from "react-i18next";
const Ceo = () => {
  // const GRAY_COLOR = "#565656";
    // const LIGHT_GRAY_COLOR = "#565656";
    const { t } = useTranslation(["ceo"]);
    
    return (
      <div id="Ceo">
        <div className="ceoWeb">
          <TopImgCompanyWeb />
        </div>
        <div className="ceoMobile">
          <TopImgCompanyMobile />
        </div>
        <div id="content">
        <img src={t("P01")} alt="트라이테크 tritech 기재석  CSO(주요경력)

국립삼척대학교 : 산업공학과부교수 (가상현실 Lab. 운영)

㈜케이씨이아이 : 연구소장 (국방 VR Simulator 사업책임)

광운대햑교 : 연구교수 (전자과 BK21 연구교수)

인천대학교 : 초빙교수 (산학협력전담)

상명대학교 : 일반대학원 스포츠 ICT융합학과 교수
CSO(학력)

한양대학교 산업공학 석.박사" style={{ width: "100%" }} />
<img src={t("P02")} alt="vision & mission 창의력사고 인간중심 최첨단기술"  />
        <img
          src={t("P03")}
          alt="temp"
          
        />
        <img src={t("P04")} alt="조직도"  />
        <img
          src={t("P05")}
          alt="연혁"
          
        />
        </div>
        <section id="Partners">
          <MDBCardBody className="bodyPartners">
            <MDBContainer lg="12" className=" mt-5 pt-5 mb-5 pb-5">
              <MDBRow center>
                <h6>CLIENTS & PARTNERS</h6>
              </MDBRow>

              <br />
              <br />
              <br />
              <br />

              <img
                src="/img/ceo/Partner.png"
                alt="temp"
                style={{ width: "100%" }}
              />
              {/* <MDBRow className="mt-5" center>
                <h6>foreign parts</h6>
              </MDBRow> */}
              <MDBRow className="mt-5" center>
                <Fragment>
                  <MDBBtn
                    color="primary"
                    outline
                    rounded
                    href="/pages/ContactFd/contact"
                  >
                    CONTACT US!
                  </MDBBtn>
                </Fragment>
              </MDBRow>
            </MDBContainer>
          </MDBCardBody>
        </section>

        <Copyrights />
      </div>
    );
  };

export default Ceo;
